// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { catalogoScripts } from "../../../scripts/catalogo"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Formas de Pago</h2>
                <p>
                    Se declara en la raíz de la factura a emitir.
                </p>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/sat/payTypes/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>key: </b> El valor de la forma de pago
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>label:</b> El nombre de la forma de pago
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/catalogo/catalogo-productoservicios`} previous={`/api/catalogo/catalogo-usocfdi`}/>
            </div>
            <div className="side">
                <CodeBlock title="Formas de Pago" requests={catalogoScripts.paytypes.requests} response={catalogoScripts.paytypes.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
